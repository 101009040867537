import { Form, Input, Button, message, Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { getData, clearLoginInfo } from "../../redux/actions/loginActions";
import { getCurrentUser } from "../../redux/actions/userActions";

import { dataBaseLogo } from "../../assets/images";

import "./styles.scss";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const errorType = useSelector((state) => state.login.token);
  const unauthorized = errorType && errorType.message === "Unauthorized";
  const userIsBlocked = errorType && errorType.message === "User is disabled";

  const onFinish = (values) => {
    Promise.resolve(dispatch(getData(values)))
      .then(() => {
        form.resetFields();
      })
      .then(() => {
        dispatch(getCurrentUser());
      });
  };

  const error = () => {
    message.error("The username or password you entered is incorrect");
  };

  const blockMessage = () => {
    message.error(
      "Your account was temporarily blocked. Please contact your administrator."
    );
  };

  useEffect(() => {
    if (unauthorized) {
      Promise.resolve(dispatch(clearLoginInfo())).then(() => {
        error();
      });
    }

    if (userIsBlocked) {
      Promise.resolve(dispatch(clearLoginInfo())).then(() => {
        blockMessage();
      });
    }
  }, [dispatch, unauthorized, userIsBlocked]);

  return (
    <Layout
      className="login-page"
      style={{ minHeight: "100vh", background: "#92C83E" }}
    >
      <div className="logo-group">
        <div className="data-base-logo">{dataBaseLogo()}</div>
      </div>
      <Form
        className="login-form"
        name="login-form"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          className="user-name"
          label="email"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            style={{
              borderRadius: "8px",
            }}
          />
        </Form.Item>
        <Form.Item
          className="password"
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            style={{
              borderRadius: "8px",
            }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <div className="forgot-button">
          <Link to="/reset">Forgot your password?</Link>
        </div>

        <Form.Item className="submit-button">
          <Button htmlType="submit">Login</Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default LoginPage;
