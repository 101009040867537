import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import { dataBaseLogo } from "../../assets/images";
import SearchForm from "../../components/SearchForm/SearchForm";
import SearchResultsTable from "../../components/SearchResultsTable/SearchResultsTable";
import { Spin } from "antd";
import "./styles.scss";

// const defaultPagination = {
//   current: 1,
//   pageSize: 25,
// };

const SearchPage = () => {
  const search = useSelector((state) => state.search);
  const { isLoading, results } = search;
  const [searchResults, setSearchResults] = useState();

  // useEffect(() => {
  //   const size = defaultPagination.pageSize;
  //   const page = defaultPagination.current;

  //   if (!results) {
  //     dispatch(getSearchResults(size, page, null, null, abortController));
  //   }

  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);

  useEffect(() => {
    setSearchResults(results);
  }, [results]);

  return (
    <Spin spinning={isLoading}>
      <Layout
        className="search-page"
        style={{ minHeight: "100vh", background: "#92C83E" }}
      >
        <div className="logo-wrapper">
          <div className="logo">{dataBaseLogo()}</div>
        </div>
        <SearchForm />
        <SearchResultsTable searchResults={searchResults} />
      </Layout>
    </Spin>
  );
};

export default SearchPage;
