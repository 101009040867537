import {
  GET_SEARCH_RESULTS_REQUEST,
  GET_SEARCH_RESULTS_ERROR,
  GET_SEARCH_RESULTS_SUCCESS,
  EXPORT_TO_XLS_REQUEST,
  EXPORT_TO_XLS_ERROR,
  EXPORT_TO_XLS_SUCCESS,
  EXPORT_TO_PDF_REQUEST,
  EXPORT_TO_PDF_ERROR,
  EXPORT_TO_PDF_SUCCESS,
  GET_SEARCH_FIELDS,
  GET_TABLE_PARAMS,
  CLEAR_ALL,
  SEARCH_BY_COUNTRIES_REQUEST,
  SEARCH_BY_COUNTRIES_SUCCESS,
  SEARCH_BY_COUNTRIES_ERROR,
  SEARCH_BY_APPLICANTS_REQUEST,
  SEARCH_BY_APPLICANTS_ERROR,
  SEARCH_BY_APPLICANTS_SUCCESS,
  SEARCH_BY_ACTIVITIES_REQUEST,
  SEARCH_BY_ACTIVITIES_ERROR,
  SEARCH_BY_ACTIVITIES_SUCCESS,
  SEARCH_BY_PRODUCTS_REQUEST,
  SEARCH_BY_PRODUCTS_ERROR,
  SEARCH_BY_PRODUCTS_SUCCESS,
  SEARCH_BY_SOURCES_REQUEST,
  SEARCH_BY_SOURCES_ERROR,
  SEARCH_BY_SOURCES_SUCCESS,
  SEARCH_BY_STANDARTS_REQUEST,
  SEARCH_BY_STANDARTS_ERROR,
  SEARCH_BY_STANDARTS_SUCCESS,
} from "../constants/searchPage";
import * as FileSaver from "file-saver";
import moment from "moment";
import { RSAA } from "redux-api-middleware";
import _ from "lodash";

import {
  SEARCH,
  EXPORT_XLS,
  EXPORT_PDF,
  SEARCH_BY_COUNTRIES,
  SEARCH_BY_APPLICANTS,
  SEARCH_BY_ACTIVITIES,
  SEARCH_BY_PRODUCTS,
  SEARCH_BY_SOURCES,
  SEARCH_BY_STANDARTS,
} from "../../helpers/apiConstants";

// const URL = process.env.REACT_APP_API_URL;
const URL = "https://tradin-dsa.com/";
// const URL = "http://tradin-dsa.com:8085/";

export const getSearchResults = (
  size,
  page,
  sortType,
  searchValues,
  controller = {}
) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const checkPage = page === 0 ? 0 : page - 1;

    dispatch({ type: GET_SEARCH_RESULTS_REQUEST });

    const requestOptions = {
      method: "GET",
      signal: controller?.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let searchResults = null;
    let searchParams = [];
    !_.isEmpty(searchValues) &&
      _.map(Object.keys(searchValues), (key) => {
        const currentParam = key;
        const currentValue = searchValues[key];
        encodeURIComponent(currentValue);

        _.forEach(currentValue, (value) => {
          if (value.includes(",")) {
            return value.replace(",", "~");
          }

          return value;
        });

        const filtered = _.filter(currentValue, (item) => item !== "");

        if (filtered.length > 0 && filtered !== undefined) {
          searchParams.push(`${currentParam}=${currentValue.join("~")}`);
        }
      });

    if (searchParams && searchParams.length > 1) {
      searchParams = searchParams.join("&");
    }

    try {
      const request = await fetch(
        `${URL}${SEARCH}?size=${size}&page=${checkPage}&${searchParams}&${sortType}`,
        requestOptions
      );
      const result = await request.json();
      searchResults = result;
    } catch (error) {
      console.log("Error fetching search data from the server", error);
    }

    if (!searchResults) {
      dispatch({ type: GET_SEARCH_RESULTS_ERROR });
    }

    return dispatch({
      type: GET_SEARCH_RESULTS_SUCCESS,
      searchResults,
    });
  };
};

export const exportToXls = (dataFromSearchFields) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const data = moment().format("L");

    let searchParams = [];
    !_.isEmpty(dataFromSearchFields) &&
      _.map(Object.keys(dataFromSearchFields), (key) => {
        const currentParam = key;
        const currentValue = dataFromSearchFields[key];
        encodeURIComponent(currentValue);

        _.forEach(currentValue, (value) => {
          if (value.includes(",")) {
            return value.replace(",", "~");
          }

          return value;
        });

        const filtered = _.filter(currentValue, (item) => item !== "");

        if (filtered.length > 0 && filtered !== undefined) {
          searchParams.push(`${currentParam}=${currentValue.join("~")}`);
        }
      });

    if (searchParams && searchParams.length > 1) {
      searchParams = searchParams.join("&");
    }

    dispatch({ type: EXPORT_TO_XLS_REQUEST });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let xlsResults = null;
    const chechSearchData = searchParams && `?${searchParams}`;

    try {
      const request = await fetch(
        `${URL}${EXPORT_XLS}${chechSearchData}`,
        requestOptions
      );
      const result = await request.arrayBuffer();
      xlsResults = result;
    } catch (error) {
      console.log("Error fetching export xls data from the server", error);
    }

    if (!xlsResults) {
      dispatch({ type: EXPORT_TO_XLS_ERROR });
    }

    let blob = new Blob([xlsResults], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, `${data}.xlsx`);

    return dispatch({
      type: EXPORT_TO_XLS_SUCCESS,
    });
  };
};

export const exportToPdf = (dataFromSearchFields) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const data = moment().format("L");

    let searchParams = [];
    !_.isEmpty(dataFromSearchFields) &&
      _.map(Object.keys(dataFromSearchFields), (key) => {
        const currentParam = key;
        const currentValue = dataFromSearchFields[key];
        encodeURIComponent(currentValue);

        _.forEach(currentValue, (value) => {
          if (value.includes(",")) {
            return value.replace(",", "~");
          }

          return value;
        });

        const filtered = _.filter(currentValue, (item) => item !== "");

        if (filtered.length > 0 && filtered !== undefined) {
          searchParams.push(`${currentParam}=${currentValue.join("~")}`);
        }
      });

    if (searchParams && searchParams.length > 1) {
      searchParams = searchParams.join("&");
    }

    dispatch({ type: EXPORT_TO_PDF_REQUEST });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let pdfResults = null;
    const chechSearchData = searchParams && `?${searchParams}`;

    try {
      const request = await fetch(
        `${URL}${EXPORT_PDF}${chechSearchData}`,
        requestOptions
      );
      const result = await request.arrayBuffer();
      pdfResults = result;
    } catch (error) {
      console.log("Error fetching export xls data from the server", error);
    }

    if (!pdfResults) {
      dispatch({ type: EXPORT_TO_PDF_ERROR });
    }

    let blob = new Blob([pdfResults], { type: "application/pdf" });
    FileSaver.saveAs(blob, `${data}.pdf`);

    return dispatch({
      type: EXPORT_TO_PDF_SUCCESS,
    });
  };
};

export const getSeachDataFromFields = (searchFieldsValues) => ({
  type: GET_SEARCH_FIELDS,
  searchFieldsValues,
});

export const getTableParams = (tableParams) => ({
  type: GET_TABLE_PARAMS,
  tableParams,
});

export const clearAllSearchData = () => ({ type: CLEAR_ALL });

export const getCountriesSearchResults = () => {
  const token = localStorage.getItem("token");
  return {
    [RSAA]: {
      endpoint: `${URL}${SEARCH_BY_COUNTRIES}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      types: [
        SEARCH_BY_COUNTRIES_REQUEST,
        {
          type: SEARCH_BY_COUNTRIES_SUCCESS,
          payload: (action, state, res) => {
            const countries = res.json();
            return countries;
          },
        },
        SEARCH_BY_COUNTRIES_ERROR,
      ],
    },
  };
};

export const getApplicantsSearchResults = () => {
  const token = localStorage.getItem("token");
  return {
    [RSAA]: {
      endpoint: `${URL}${SEARCH_BY_APPLICANTS}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      types: [
        SEARCH_BY_APPLICANTS_REQUEST,
        {
          type: SEARCH_BY_APPLICANTS_SUCCESS,
          payload: (action, state, res) => {
            const applicants = res.json();
            return applicants;
          },
        },
        SEARCH_BY_APPLICANTS_ERROR,
      ],
    },
  };
};

export const getActivitiesSearchResults = () => {
  const token = localStorage.getItem("token");
  return {
    [RSAA]: {
      endpoint: `${URL}${SEARCH_BY_ACTIVITIES}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      types: [
        SEARCH_BY_ACTIVITIES_REQUEST,
        {
          type: SEARCH_BY_ACTIVITIES_SUCCESS,
          payload: (action, state, res) => {
            const activities = res.json();
            return activities;
          },
        },
        SEARCH_BY_ACTIVITIES_ERROR,
      ],
    },
  };
};

export const getProductsSearchResults = () => {
  const token = localStorage.getItem("token");
  return {
    [RSAA]: {
      endpoint: `${URL}${SEARCH_BY_PRODUCTS}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      types: [
        SEARCH_BY_PRODUCTS_REQUEST,
        {
          type: SEARCH_BY_PRODUCTS_SUCCESS,
          payload: (action, state, res) => {
            const products = res.json();
            return products;
          },
        },
        SEARCH_BY_PRODUCTS_ERROR,
      ],
    },
  };
};

export const getSourcesSearchResults = () => {
  const token = localStorage.getItem("token");
  return {
    [RSAA]: {
      endpoint: `${URL}${SEARCH_BY_SOURCES}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      types: [
        SEARCH_BY_SOURCES_REQUEST,
        {
          type: SEARCH_BY_SOURCES_SUCCESS,
          payload: (action, state, res) => {
            const sources = res.json();
            return sources;
          },
        },
        SEARCH_BY_SOURCES_ERROR,
      ],
    },
  };
};

export const getStandardSearchResults = () => {
  const token = localStorage.getItem("token");
  return {
    [RSAA]: {
      endpoint: `${URL}${SEARCH_BY_STANDARTS}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      types: [
        SEARCH_BY_STANDARTS_REQUEST,
        {
          type: SEARCH_BY_STANDARTS_SUCCESS,
          payload: (action, state, res) => {
            const standard = res.json();
            return standard;
          },
        },
        SEARCH_BY_STANDARTS_ERROR,
      ],
    },
  };
};
