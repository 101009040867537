import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_GET_USER_SUCCESS,
  CREATE_NEW_USER_ERROR,
  DELETE_CURRENT_USER_REQUEST,
  DELETE_CURRENT_USER_ERROR,
  DELETE_CURRENT_USER_SUCCESS,
  ENABLE_DISABLE_USER_REQUEST,
  ENABLE_DISABLE_USER_ERROR,
  ENABLE_DISABLE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_ERROR,
  CHANGE_USER_PASSWORD_SUCCESS,
} from "../constants/user";

import { USER, USERS } from "../../helpers/apiConstants";

// const URL = process.env.REACT_APP_API_URL;
const URL = "https://tradin-dsa.com/";
// const URL = "http://tradin-dsa.com:8085/";

export const getCurrentUser = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    dispatch({ type: GET_USER_REQUEST });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let payload = null;

    try {
      const response = await fetch(`${URL}${USER}`, requestOptions);
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: GET_USER_ERROR });
    }

    return dispatch({
      type: GET_USER_SUCCESS,
      payload,
    });
  };
};

export const createNewUser = (user) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    dispatch({ type: CREATE_NEW_USER_REQUEST });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    };

    let payload = null;

    try {
      const response = await fetch(`${URL}${USERS}`, requestOptions);
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: CREATE_NEW_USER_ERROR });
    }

    return dispatch({ type: CREATE_NEW_USER_GET_USER_SUCCESS });
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    dispatch({ type: DELETE_CURRENT_USER_REQUEST });

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let payload = null;

    try {
      const response = await fetch(`${URL}${USERS}/${id}`, requestOptions);
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: DELETE_CURRENT_USER_ERROR });
    }

    return dispatch({ type: DELETE_CURRENT_USER_SUCCESS });
  };
};

export const setEnableDisableUser = (id, option) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    dispatch({ type: ENABLE_DISABLE_USER_REQUEST });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let payload = null;

    try {
      const response = await fetch(
        `${URL}${USERS}/${id}/${option}`,
        requestOptions
      );
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: ENABLE_DISABLE_USER_ERROR });
    }

    return dispatch({ type: ENABLE_DISABLE_USER_SUCCESS });
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    dispatch({ type: UPDATE_USER_REQUEST });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    };

    let payload = null;

    try {
      const response = await fetch(`${URL}${USERS}`, requestOptions);
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: UPDATE_USER_ERROR });
    }

    return dispatch({ type: UPDATE_USER_SUCCESS });
  };
};

export const changePassword = (id, newPassword) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    dispatch({ type: CHANGE_USER_PASSWORD_REQUEST });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newPassword),
    };

    let payload = null;

    try {
      const response = await fetch(
        `${URL}${USERS}/${id}/password`,
        requestOptions
      );
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: CHANGE_USER_PASSWORD_ERROR });
    }

    return dispatch({ type: CHANGE_USER_PASSWORD_SUCCESS });
  };
};
