import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
} from "../constants/users";
import { USERS } from "../../helpers/apiConstants";

// const URL = process.env.REACT_APP_API_URL;
const URL = "https://tradin-dsa.com/";
// const URL = "http://tradin-dsa.com:8085/";

export const getAllUsers = (page, pageSize) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }
    dispatch({ type: GET_ALL_USERS_REQUEST });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let payload = null;

    try {
      const response = await fetch(
        `${URL}${USERS}?size=${pageSize}&page=${page}`,
        requestOptions
      );
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching users data from the server", error);
    }

    if (!payload) {
      dispatch({ type: GET_ALL_USERS_ERROR });
    }

    return dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload,
    });
  };
};
